exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-examples-edit-tsx": () => import("./../../../src/pages/examples/edit.tsx" /* webpackChunkName: "component---src-pages-examples-edit-tsx" */),
  "component---src-pages-examples-test-1-tsx": () => import("./../../../src/pages/examples/test1.tsx" /* webpackChunkName: "component---src-pages-examples-test-1-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-detail-tsx": () => import("./../../../src/pages/property/detail.tsx" /* webpackChunkName: "component---src-pages-property-detail-tsx" */),
  "component---src-pages-property-stock-tsx": () => import("./../../../src/pages/property/stock.tsx" /* webpackChunkName: "component---src-pages-property-stock-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

